<template>
  <vf-dialog-layout class-wrapper="!p-0">
    <template #title>
      {{ $t.selectAttribute.size }}
    </template>
    <section v-if="!closeOnAdd" data-scroll-el="notificationSection">
      <vf-notification
        v-if="cart.notification.message"
        class="mx-2 mb-2 mt-3 md:mb-4 md:mt-2"
        :type="cart.notification.type"
        @close="cart.notification.message = ''"
      >
        {{ cart.notification.message }}
      </vf-notification>
    </section>
    <div v-if="productPending || inventoryPending">
      <div v-for="key in 5" :key class="h-12 skeleton b-b b-white" />
    </div>
    <div v-else ref="sizePickersEl">
      <base-radio
        v-for="(option, key) in options"
        :key
        data-test-id="vf-size-picker"
        :model-value="selectedSize"
        :name="`${details?.id || product?.id}-quickshop`"
        :value="option.value"
        @update:model-value="selectSize(option)"
      >
        <span
          class="h-12 flex cursor-pointer items-center b-b b-grey-80 px-6 between peer-focus-visible:outline-auto"
          :class="{
            'bg-grey-90 c-grey-20': selectedSize !== option.value && !option.available,
            'bg-black c-white': selectedSize === option.value,
          }"
        >
          <span>{{ option.label }}</span>
          <span v-if="$feature.showSizeLevelStockMessagingOnPDP && getStockStatus(option.value)" class="text-xs capitalize">
            {{ getStockStatus(option.value) }}
          </span>
          <template v-if="details?.productType !== 'Digital' && !option.inStock">
            <base-button v-if="isNotifyMe && option.notifyMe" class="text-xs fw-medium underlined" @click="handleNotifyMe(option.value)">
              {{ $t.notifyMe }}
            </base-button>
            <span v-else class="text-xs">{{ $t.outOfStock }}</span>
          </template>
        </span>
      </base-radio>
    </div>
    <template v-if="selectedSize" #footer>
      <vf-button v-if="isNotifyMe && isOutOfStock" class="w-full" @click="handleNotifyMe(selectedSize)">
        {{ $t.notifyMe }}
      </vf-button>
      <vf-button v-else-if="isOutOfStock" class="w-full" disabled>
        {{ $t.outOfStock }}
      </vf-button>
      <vf-button v-else-if="isSignInToBuy" class="w-full" @click="signInToBuy">
        {{ $t.signInToBuy }}
      </vf-button>
      <vf-button v-else-if="context" class="w-full" :loading="loading" @click="updateInCart">
        {{ $t.updateInCart }}
      </vf-button>
      <vf-button v-else class="w-full" :loading="loading" @click="addToCart">
        {{ $t.addToCart }}
      </vf-button>
    </template>
    <dialog-sign-in />
    <dialog-notify-me size="sm" />
  </vf-dialog-layout>
</template>

<script lang="ts" setup>
import type { Product, ProductVariants } from '#root/api/clients/product/data-contracts'
import { ApiErrorCode } from '#root/enums/api'
import type { CSSClass } from '#types/common'
import type { VariantWithStockStatus } from '#types/product'
import type { InteractionOrigin, ProductContext, ShippingMethodContext } from '#types/gtm'

export type Props = {
  productId?: string
  product?: Product
  closeOnAdd?: boolean
  context?: 'update-cart-item' | 'update-saved-item'
  classProductName?: CSSClass
  initialAttributes?: ProductVariants[number]['attributes']
  updateProductId?: (id: string) => void
  categoryURL?: string
  gtmConfig?: ProductContext & ShippingMethodContext & { appSource?: InteractionOrigin }
  storeId?: string
}
export type Emits = {
  resolve: []
}

const { closeOnAdd, context, gtmConfig, product, storeId } = defineProps<Props>()
const emit = defineEmits<Emits>()

const productId = defineModel<string>('productId')

const { defaultImageUrl, notifyMe: { dialogOptions } } = useAppConfig().components.product
const { name } = useAttrs()
const auth = useAuthStore()
const cart = useCartStore()
const currentDialog = useCurrentDialog<Props, Emits>()
const { DialogSignIn } = useDialogsStore()
const { $feature, $gtm, $locale, $sendExtraMonetateEvents, $t } = useNuxtApp()
const route = useRoute()
const toast = useToaster()
const gtmTrace = useSessionStorage('gtmTrace', {} as Record<string, ShippingMethodContext>)

const DialogNotifyMe = createDialog('notify-me', dialogOptions)

const activeProduct = ref(productId.value || product?.id || '')
const loading = ref(false)
const parentInteractionOrigin = ref(getInteractionOrigin())
const selectedSize = ref()
const sizePickersEl = ref<HTMLElement>()

const { productPending, product: details, inventory, inventoryPending } = await useProduct(activeProduct, {
  server: false,
  // @ts-expect-error incorrect Nuxt types
  default: () => product,
  transform: (input) => ({
    ...input,
    badge: input.badge || product?.badge,
    eyebrow: input.eyebrow || product?.eyebrow
  }),
  key: `quickshop-${activeProduct.value}`,
  onResponse: async ({ response: { _data } }) => productId.value = _data.id
})

const getSelectedVariant = (size: string) => details.value?.variants?.find(({ attributes }) => attributes.size === size)

const sizeAttributes = computed(() => details.value?.attributes.find((attr) => attr.type === 'size')?.options || [])
const options = computed(() => sizeAttributes.value.map((attribute) => {
  const variant = details.value?.variants.find((variant) => variant.attributes.size === attribute.value)
  return {
    ...attribute,
    ...(variant || {}),
    ...(inventory.value?.variants?.[variant?.id || ''] || {}),
    ...(isCustomsProduct(details) && { inStock: true, quantity: 10, stockStatus: 'AVAILABLE' })
  }
}))
const isNotifyMe = computed(() => $feature.showNotifyMeOnQuickshop && getSelectedVariant(selectedSize.value)?.notifyMe)
const isOutOfStock = computed(() => getSelectedVariant(selectedSize.value)?.productInventoryState !== 'InStock')
const isSignInToBuy = computed(() => details.value?.presale && !auth.loggedIn)
const url = computed(() => details.value?.url || product?.url)

const fetchCustomsData = async (customsRecipeID, size, locale) => {
  try {
    const [{ sku }, imageUrl] = await Promise.all([
      $fetch(`/fapi/customs-sku/${customsRecipeID}`, { query: { locale, size } }),
      $fetch(`/fapi/customs-image/${customsRecipeID}`, {
        query: { locale },
        default: () => defaultImageUrl
      })
    ])
    return { productId: sku, customsProductImageURL: imageUrl }
  }
  catch {
    toast.add({
      props: {
        type: 'error',
        title: $t.somethingWentWrong,
        message: $t.itemCannotAddedToCart
      }
    })
    return { productId: undefined, customsProductImageURL: undefined }
  }
}

async function addToCart() {
  if (!details.value) return
  loading.value = true
  cart.notification.message = ''

  const selectedVariant = getSelectedVariant(selectedSize.value)!

  const customsRecipeID = details.value.customsRecipeID
  const recipeId = isCustomsProduct(details) ? customsRecipeID : undefined
  const precreatedCustomsCode = details.value.dummyCustoms ? details.value.id : undefined
  const { productId, customsProductImageURL } = customsRecipeID && details.value.dummyCustoms
    ? await fetchCustomsData(customsRecipeID, selectedVariant.attributes.size, $locale)
    : { productId: selectedVariant.id, customsProductImageURL: undefined }

  if (!productId) {
    loading.value = false
    return
  }

  setGtmCartProductsMap(productId, details.value)

  try {
    const { shippingMethod, shippingStoreId, shippingWindow } = gtmConfig || {}

    if (shippingMethod && shippingStoreId && shippingWindow)
      gtmTrace.value[productId] = { shippingMethod, shippingStoreId, shippingWindow }

    await withRetryAfterTime(() => cart.add({
      product: productId,
      upc: selectedVariant.upc,
      suppressMiniCart: name === 'shopTheLook',
      suppressNotification: name === 'shopTheLook',
      maxQty: 1,
      storeId: shippingStoreId,
      extendedGtmConfig: {
        ...gtmConfig,
        category: gtmConfig?.category || interactionOriginMap[gtmConfig?.appSource!]
      },
      productImageURL: customsProductImageURL,
      recipeId,
      precreatedCustomsCode
    }), 2000, [ApiErrorCode.GWP_PRODUCT])
    $sendExtraMonetateEvents()
  }
  catch (err) {
    log.error(err)
    delete gtmTrace.value[productId]
  }

  loading.value = false

  if (cart.error) {
    cart.setNotification('error', cart.errorMessage)
    removeGtmCartProductsMap(productId, details.value.id)
    return
  }

  if ($feature.showMiniCartOnAddProduct || closeOnAdd)
    emit('resolve')
}

const updateInCart = async () => {
  loading.value = true

  const selectedVariant = getSelectedVariant(selectedSize.value)!

  try {
    if (context === 'update-cart-item') {
      const { id = '', itemId = '' } = product || {}
      await cart.updateItemInCart(
        itemId,
        id,
        activeProduct.value,
        selectedVariant.id,
        selectedVariant.upc,
        undefined,
        undefined,
        storeId
      )
    }

    if (context === 'update-saved-item')
      await cart.updateSavedItemInCart(product!.itemId as string, selectedVariant.id as string, url.value)
  }
  catch (err) {
    log.error(err)
    delete gtmTrace.value[selectedVariant.id]
  }

  loading.value = false

  if (cart.error) {
    cart.setNotification('error', cart.errorMessage)
    if (selectedVariant.id && details.value) removeGtmCartProductsMap(selectedVariant.id, details.value.id)
  }

  if ($feature.showMiniCartOnAddProduct || closeOnAdd)
    emit('resolve')
}

const productColor = useProductColor(details)

const selectSize = ({ value, label }) => {
  selectedSize.value = value

  const eventLabel = `${activeProduct.value} - ${label}`

  if (name === 'shopTheLook')
    $gtm.push('shopTheLook.onSelectSize', eventLabel, { bundleId: gtmConfig!.bundleId! })
  else
    $gtm.push('plpPage.onSelectSize', eventLabel)
}

const handleNotifyMe = (size: string) => {
  const selectedVariant = getSelectedVariant(size)!
  const attributeSelectionLabels = useAttributeSelectionLabels(details, { size })
  const setNotification = ({ message, type }) => toast.add({
    props: {
      type,
      message
    }
  })

  DialogNotifyMe.keep().open({
    productId: selectedVariant.id,
    selectedAttributes: attributeSelectionLabels.value,
    gtmEventLabel: `${details.value?.id} - ${productColor.value?.label}: ${size}`,
    setNotification,
    subscriptionType: details.value?.subscriptionType,
    productName: details.value?.name || '',
    onNotifySignUp: () => emit('resolve')
  })

  $gtm.push('pdpPage.onNotifyMeOpen', activeProduct.value)
}

const signInToBuy = async () => {
  await DialogSignIn.keep().open({
    formLocation: 'modal:single:quickshop:none',
    isSignInToBuy: true
  })
  if (auth.loggedIn) {
    await until(() => cart.pending).toBe(false)
    addToCart()
  }
}

const getStockStatus = (optionValue: string) => {
  if (!details.value) return
  const variant = details.value?.variants.find((variant) =>
    variant.attributes.size === optionValue) as VariantWithStockStatus

  if (variant?.stockStatusLabel)
    return variant.stockStatusLabel.toLowerCase()

  return null
}

const pushProductDetailViewEvent = (product) => {
  $gtm.push('product.onProductDetailView', product, {
    viewType: 'Quick Shop',
    breadcrumbs: deserializeAnalyticsBreadcrumbs(history.state.breadcrumbs),
    bundleId: gtmConfig?.bundleId,
    category: interactionOriginMap[gtmConfig?.appSource!] || history.state.category,
    searchTerm: route.query.q?.toString(),
    stockStatus: inventory.value?.variants[product?.id!]?.stockStatus
  })
}

// Push updated event with inventory data once it becomes available
watch(inventory, (newInventory) => {
  if (newInventory && details.value)
    pushProductDetailViewEvent(details.value)
}, { immediate: false })

onMounted(async () => {
  if (currentDialog.reopened) {
    await until(sizePickersEl).toBeTruthy()
    addToCart()
  }
  cart.clearNotification()

  if (interactionOriginMap[gtmConfig?.appSource!])
    setInteractionOrigin(gtmConfig!.appSource, true)
  else
    setInteractionOrigin()
})

onUnmounted(() => {
  if (!closeOnAdd)
    cart.clearNotification()

  if (interactionOriginMap[gtmConfig?.appSource!])
    setInteractionOrigin(parentInteractionOrigin.value || '')
})

if (!closeOnAdd) {
  watch(cart.notification, () => {
    if (cart.notification.message)
      scrollToElement('notificationSection')
  })
}
</script>
